// Our main CSS
import '../css/app.css'

/**
 * 🦄: Now do your magic.
 */

import initializeMobileMenu from './components/MobileMenu'
import LazyLoad from 'vanilla-lazyload'

initializeMobileMenu()

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: 25,
})

window.lazyload = lazyLoad

const anchorTags = document.querySelectorAll<HTMLAnchorElement>('a')
anchorTags.forEach((tag) => {
  if (tag.href === '#' || tag.getAttribute('href') === '#') {
    tag.href = 'javascript:;'
  }
})

const TextImageSliders = document.querySelectorAll<HTMLElement>('[data-js="TextImageSlider"]')
if (TextImageSliders && TextImageSliders !== null) {
  import('./components/TextImageSlider')
    .then((module) => {
      module.default(TextImageSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const CardSliders = document.querySelectorAll<HTMLElement>('[data-js="CardSlider"]')
if (CardSliders && CardSliders !== null) {
  import('./components/CardSlider')
    .then((module) => {
      module.default(CardSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AccordionTriggers = document.querySelectorAll<HTMLElement>('[data-js="AccordionTrigger"]')
if (AccordionTriggers && AccordionTriggers !== null) {
  import('./components/Accordion')
    .then((module) => {
      module.default(AccordionTriggers as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const Videos = document.querySelectorAll<HTMLElement>('[data-js="player"]')
if (Videos && Videos !== null) {
  import('./components/Video')
    .then((module) => {
      module.default(Videos as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const scrollToTop = document.querySelectorAll<HTMLElement>('[data-js="ScrollToTop"]')
scrollToTop?.forEach(function (el) {
  el.addEventListener('click', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
})

const NewsFilterContainer = document.getElementById('VueNewsFilterContainer')
if (NewsFilterContainer && NewsFilterContainer !== null) {
  import('./NewsFilter/index.ts')
    .then((module) => {
      module.default(NewsFilterContainer as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueContactForm = document.getElementById('VueContactForm')
if (VueContactForm && VueContactForm !== null) {
  import('./ContactForm/index.ts')
    .then((module) => {
      module.default(VueContactForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueNewsletterForm = document.getElementById('VueNewsletterForm')
if (VueNewsletterForm && VueNewsletterForm !== null) {
  import('./NewsletterForm/index.ts')
    .then((module) => {
      module.default(VueNewsletterForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueTrafficReportForm = document.getElementById('VueTrafficReportForm')
if (VueTrafficReportForm && VueTrafficReportForm !== null) {
  import('./TrafficReportForm/index.ts')
    .then((module) => {
      module.default(VueTrafficReportForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const searchButtonToggle = document.querySelector<HTMLElement>('[data-js="SearchButtonToggle"]')
if (searchButtonToggle && searchButtonToggle !== null) {
  const searchInput = document.querySelector<HTMLElement>('[data-js="SearchInput"]')
  searchButtonToggle.addEventListener('click', () => {
    searchInput?.classList.toggle('active')

    if (searchInput?.classList.contains('active')) {
      searchInput?.querySelector('input')?.focus()
    }
  })
}

const VueGroupBookingForm = document.getElementById('VueGroupBookingForm')
if (VueGroupBookingForm && VueGroupBookingForm !== null) {
  import('./GroupBookingForm/index.ts')
    .then((module) => {
      module.default(VueGroupBookingForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const VueBookingRequestForm = document.getElementById('VueBookingRequestForm')
if (VueBookingRequestForm && VueBookingRequestForm !== null) {
  import('./BookingRequestForm/index.ts')
    .then((module) => {
      module.default(VueBookingRequestForm as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
    })
}

const OverlayVideoButton = document.querySelectorAll<HTMLElement>('[data-js="ShowOverlayVideo"]')
OverlayVideoButton.forEach((element) => {
  element.addEventListener('click', () => {
    const overlay = document.querySelector<HTMLElement>('[data-js="VideoOverlay"]')
    overlay?.classList.replace('opacity-0', 'opacity-100')
    overlay?.classList.replace('pointer-events-none', 'pointer-events-auto')

    const videos = overlay?.querySelectorAll<HTMLElement>('[data-js="player"]')
    if (videos && videos !== null) {
      import('./components/Video')
        .then((module) => {
          module.default(videos as NodeListOf<HTMLElement>)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const closeOverlay = overlay?.querySelector<HTMLElement>('[data-js="CloseVideoOverlay"]')
    closeOverlay?.addEventListener('click', () => {
      overlay?.classList.replace('opacity-100', 'opacity-0')
      overlay?.classList.replace('pointer-events-auto', 'pointer-events-none')
    })
  })
})
